export interface IBackendConfig {
  domain: string
  clientId: string
  audience: string
  stripePk: string
  portalLink: string
}
export const backendConfig: {[env: string]: IBackendConfig } = {
  dev: {
    domain: 'neptune-staging.au.auth0.com',
    clientId: '3oftN5JYHpIIRf1lBj0O5IzcHymyj1Cr',
    audience: 'https://proteus.staging.neptune.net.au',
    stripePk: 'pk_test_51NdskjH15bVe1zSbhvewvo4HAQkSLapVeKJVcvh9vCWpScn3a26V9VgW2FX5xrCW79mxAJHANTa19yg4bDpLw0zE00IhAnW0bR',
    portalLink: 'https://checkout.neptune.net.au/p/login/test_bIYaEWgvRgku2QweUU?prefilled_email=',
  },
  staging: {
    domain: 'neptune-staging.au.auth0.com',
    clientId: '3oftN5JYHpIIRf1lBj0O5IzcHymyj1Cr',
    audience: 'https://proteus.staging.neptune.net.au',
    stripePk: 'pk_test_51NdskjH15bVe1zSbhvewvo4HAQkSLapVeKJVcvh9vCWpScn3a26V9VgW2FX5xrCW79mxAJHANTa19yg4bDpLw0zE00IhAnW0bR',
    portalLink: 'https://checkout.neptune.net.au/p/login/test_bIYaEWgvRgku2QweUU?prefilled_email=',
  },
  prod: {
    domain: 'auth.neptune.net.au',
    clientId: 'EDjZi2vnKZuv6BE0DwL44NZsDtP6ocs2',
    audience: 'https://proteus.neptune.net.au',
    stripePk: 'pk_live_51NdskjH15bVe1zSbfdQCxgoYU6sanKVlPhGUQIRjhnTxG7dLs29hJpSuFssnJY6rObe1zuNd8X6zO9l3MgOOnSMy00OXU5O8F2',
    portalLink: 'https://checkout.neptune.net.au/p/login/9AQ6pa5b64TbbEk7ss?prefilled_email=',
  },
};

export const getConfig = () => {
  const location = window.location.host;

  switch (location) {
    case 'localhost:3000':
      return backendConfig.dev;
    case 'triton.staging.neptune.net.au':
      return backendConfig.staging;
    case 'triton.neptune.net.au':
      return backendConfig.prod;

    default:
      return backendConfig.dev;
  }
};
