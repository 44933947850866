import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { CustomerObject } from '../../models/customer';
import { Atmosphere } from '../../models/groups';

export interface CustomerObjectState {
  value: CustomerObject;
  atmosphere: Atmosphere;
  message: string
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CustomerObjectState = {
  value: {
  } as CustomerObject,
  message: '',
  atmosphere: {} as Atmosphere,
  status: 'idle',
};

export const fetchCustomerObject = createAsyncThunk(
  'customer/fetchCustomer',
  async (token: string) => {
    const respJson = await fetch('/api/v1/customer', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      ...respJson,
    };
  },
);

export const fetchGroupsAsync = createAsyncThunk(
  'customer/fetchGroupsAsync',
  async (token: string) => {
    const respJson = await fetch('/api/v1/customer/atmosphere/groups', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      ...respJson,
    };
  },
);

export interface setGroupsAsyncProps {
  token: string
  subId: string
  payload: {
    groups: number[],
  },
}

export interface permitAsyncRegexProps {
  token: string
  subId: string
  payload: {
    allow_list_regex_domains: string[],
  },
}

export const setGroupsAsync = createAsyncThunk(
  'customer/setGroups',
  async ({ token, subId, payload }: setGroupsAsyncProps) => {
    if (payload.groups.includes(0)) {
      const respJson = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId)}/atmosphere/allowregex`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // enable this by default whenever recommended is chose
          /* eslint-disable */
          allow_list_regex_domains: ['(\.|^)googleadservices\.com$', '(\.|^)ad\.doubleclick\.net$', '(\.|^)dartsearch\.net$'],
        }),
      })
        .then((response) => response.json())
        .catch((error) => {
          throw error;
        });
    }

    const respJson = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId)}/atmosphere`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      subId,
      ...respJson,
    };
  },
);

export const permitAsyncRegex = createAsyncThunk(
  'customer/permitAsyncRegex',
  async ({ token, subId, payload }: permitAsyncRegexProps) => {
    const respJson = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId)}/atmosphere/allowregex`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      subId,
      ...respJson,
    };
  },
);

export const removePermitAsyncRegex = createAsyncThunk(
  'customer/removePermitAsyncRegex',
  async ({ token, subId, payload }: permitAsyncRegexProps) => {
    const respJson = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId)}/atmosphere/allowregex/delete`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      subId,
      ...respJson,
    };
  },
);

export const permitAsyncExact = createAsyncThunk(
  'customer/permitAsyncExact',
  async ({ token, subId, payload }: setGroupsAsyncProps) => {
    const respJson = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId)}/atmosphere/allowexact`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      subId,
      ...respJson,
    };
  },
);

export const denyAsyncExact = createAsyncThunk(
  'customer/denyAsyncExact',
  async ({ token, subId, payload }: setGroupsAsyncProps) => {
    const respJson = await fetch(`/api/v1/customer/subscription/${encodeURIComponent(subId)}/atmosphere/denyexact`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });

    return {
      subId,
      ...respJson,
    };
  },
);

export const customerObjectSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    reset: (state) => {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerObject.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerObject.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchCustomerObject.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(setGroupsAsync.pending, (state) => {
        state.status = 'loading';
        state.message = '';
      })
      .addCase(setGroupsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.subscriptions[action.payload.subId].dns_neptune_groups = action.payload.groups;
        state.message = 'Atmosphere has been successfully updated.';
      })
      .addCase(setGroupsAsync.rejected, (state) => {
        state.status = 'failed';
        state.message = 'Error occurred while updating atmosphere.';
      })

      .addCase(permitAsyncRegex.pending, (state) => {
        state.status = 'loading';
        state.message = '';
      })
      .addCase(permitAsyncRegex.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.subscriptions[action.payload.subId].dns_allowed_regex_domains = action.payload.allow_list_regex_domains;
        state.message = 'Atmosphere allow-lists have been successfully updated.';
      })
      .addCase(permitAsyncRegex.rejected, (state) => {
        state.status = 'failed';
        state.message = 'Error occurred while updating atmosphere.';
      })

      .addCase(removePermitAsyncRegex.pending, (state) => {
        state.status = 'loading';
        state.message = '';
      })
      .addCase(removePermitAsyncRegex.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.subscriptions[action.payload.subId].dns_allowed_regex_domains = action.payload.allow_list_regex_domains;
        state.message = 'Atmosphere has been successfully updated.';
      })
      .addCase(removePermitAsyncRegex.rejected, (state) => {
        state.status = 'failed';
        state.message = 'Error occurred while updating atmosphere.';
      })

      .addCase(permitAsyncExact.pending, (state) => {
        state.status = 'loading';
        state.message = '';
      })
      .addCase(permitAsyncExact.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.subscriptions[action.payload.subId].dns_allowed_exact_domains = action.payload.allow_list_exact_domains;
        state.message = 'Atmosphere has been successfully updated.';
      })
      .addCase(permitAsyncExact.rejected, (state) => {
        state.status = 'failed';
        state.message = 'Error occurred while updating atmosphere.';
      })

      .addCase(denyAsyncExact.pending, (state) => {
        state.status = 'loading';
        state.message = '';
      })
      .addCase(denyAsyncExact.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.subscriptions[action.payload.subId].dns_blocked_exact_domains = action.payload.deny_list_exact_domains;
        state.message = 'Atmosphere has been successfully updated.';
      })
      .addCase(denyAsyncExact.rejected, (state) => {
        state.status = 'failed';
        state.message = 'Error occurred while updating atmosphere.';
      })

      .addCase(fetchGroupsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGroupsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.atmosphere.groups = action.payload;
      })
      .addCase(fetchGroupsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectCustomerObject = (state: RootState) => state.customer;
export default customerObjectSlice;
