import { InfoIcon, UpDownIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Alert, AlertIcon, Text, AlertTitle, Box, Button, Heading, List, ListIcon, ListItem, Spinner, Stack, Divider, Center, Grid, GridItem, Link,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getConfig, IBackendConfig } from '../config';
import { fetchCustomerObject, fetchGroupsAsync, selectCustomerObject } from '../features/customer/customerObjectSlice';
import { Plan, Subscription } from '../models/customer';
import { Atmosphere } from './Atmosphere';
import CheckoutForm from './CheckOutForm';
import NavBar from './NavBar';
import NotAuthenticated from './NotAuthenticated';

const cfg: IBackendConfig = getConfig();
const stripePromise = loadStripe(cfg.stripePk);
export interface DashboardProps {
  email: string;
  avatarLink: string;
  token: string;
}

interface PackageTierProps {
  title: string
  plan: Plan
  sub: Subscription
  typePlan: string
  checked?: boolean
  email: string
  setShowAtmosphere: any,
  setShowSvc: any,
}

const PackageTier = ({
  title, plan, email, sub, typePlan, checked = false, setShowAtmosphere, setShowSvc,
}: PackageTierProps) => {
  const colorTextLight = checked ? 'white' : 'purple.600';
  const bgColorLight = checked ? 'purple.400' : 'gray.300';

  const colorTextDark = checked ? 'white' : 'purple.500';
  const bgColorDark = checked ? 'purple.400' : 'gray.300';

  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: 'flex-start',
        md: 'space-around',
      }}
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems={{ md: 'center' }}
    >
      <Heading size="md">{title}</Heading>
      <List spacing={3} textAlign="start">
        <ListItem>
          <ListIcon as={UpDownIcon} color="teal.500" />
          Speed:
          {' '}
          {plan.down_mbps}
          {' '}
          /
          {' '}
          {plan.up_mbps}
          {' '}
          Mbps
        </ListItem>
        <ListItem width={300}>
          <ListIcon as={InfoIcon} color="teal.500" />
          {plan.description}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          NBN LOC_ID:
          {' '}
          {sub.loc_id}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          Technology type:
          {' '}
          {sub?.technology_type || 'HFC'}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          Infrastructure ID:
          {' '}
          {sub?.infrastructure_id}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          Infrastructure Port:
          {' '}
          {sub?.infrastructure_port}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          POI:
          {' '}
          {sub?.poi || 'Unknown'}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          IP Address:
          {' '}
          {sub?.ip || 'HFC'}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          Address:
          {' '}
          {sub?.nbn_details?.addressDetail?.formattedAddress}
        </ListItem>
        <ListItem>
          <ListIcon as={InfoIcon} color="teal.500" />
          Next payment date:
          {' '}
          {new Date(+sub.current_period_end * 1000).toLocaleString()}
        </ListItem>
      </List>
      <Heading size="xl">{typePlan}</Heading>
      <Stack>
        <Button
          size="md"
          backgroundColor="purple.500"
          borderRadius="20px"
          variant="solid"
          onClick={() => {
            setShowSvc(sub);
            setShowAtmosphere(true);
          }}
        >
          Atmosphere
        </Button>
        <Button
          size="md"
          backgroundColor="teal.500"
          borderRadius="20px"
          variant="solid"
          onClick={() => {
            window.location.href = `${cfg.portalLink}${email}`;
          }}
        >
          Update
        </Button>
      </Stack>
    </Stack>
  );
};

const Dashboard = ({ email, avatarLink, token }: DashboardProps) => {
  const [secretKey, setSecretKey] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [showAtmosphere, setShowAtmosphere] = useState(false);
  const [svc, setSvc] = useState({} as Subscription);
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectCustomerObject);

  useEffect(() => {
    dispatch(fetchCustomerObject(token));
    dispatch(fetchGroupsAsync(token));
  }, []);

  if (customer.status === 'failed') {
    return (
      <>
        <Alert colorScheme="pink" w="100%" p={4} color="white">
          <AlertIcon />
          <AlertTitle>
            <a href="https://eap.neptune.net.au/">
              It looks like you are not a Neptune Internet subscriber yet. Click
              here to sign up.
            </a>
          </AlertTitle>
        </Alert>
        <NavBar email={email} avatarLink={avatarLink} />
        <NotAuthenticated />
      </>
    );
  }

  if (customer.status === 'loading') {
    return <Center><Spinner color="teal.500" speed="0.65s" thickness="4px" variant="xxl" marginTop="10%" /></Center>;
  }

  return customer.value === undefined ? <div>loading</div> : (

    <Grid
      templateAreas={`"header"
                      "main"
                      "footer"`}
      margin="5px"
      h="100%"
      minHeight="100vh"
      flexGrow="column nowrap"
      gap="1"
    >
      <GridItem>
        <Box py={6} px={5} width="full">
          <NavBar email={email} avatarLink={avatarLink} />

          {!showAtmosphere ? (
            <Stack spacing={4} width="100%" direction="column">
              <Stack
                p={5}
                alignItems="center"
                justifyContent={{
                  base: 'flex-start',
                  md: 'space-around',
                }}
                direction={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <Stack
                  width={{
                    base: '100%',
                    md: '40%',
                  }}
                  textAlign="center"
                >
                  <Heading size="lg">
                    Account Overview
                    {' '}
                    <Text color="purple.400">{customer?.value?.customer?.email}</Text>
                  </Heading>
                </Stack>
                <Stack
                  width={{
                    base: '100%',
                    md: '60%',
                  }}
                >
                  <Text textAlign="center" width="400px">
                    Hi
                    {' '}
                    {customer?.value?.customer?.first_name}
                    !
                    Here you can update your monthly plan, add additional services and update credit card details.
                  </Text>
                </Stack>
              </Stack>
              <Text>Your services: </Text>
              <Divider />
              {customer?.value?.subscriptions !== undefined && Object.keys(customer?.value?.subscriptions).map((key: string) => {
                const sub = customer?.value?.subscriptions[key] || {} as Subscription;
                console.log('SUB', customer.value.subscriptions);
                return (<PackageTier setShowSvc={setSvc} setShowAtmosphere={setShowAtmosphere} email={email} key={sub?.id} sub={sub} title={sub?.plan?.name} typePlan={`$${sub?.plan?.cost.toString()}/month`} plan={sub?.plan} />);
              })}
            </Stack>
          ) : (
            <Stack spacing={4} width="100%" direction="column">
              <Stack
                p={5}
                alignItems="center"
                justifyContent={{
                  base: 'flex-start',
                  md: 'space-around',
                }}
                direction={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <Stack
                  width={{
                    base: '100%',
                    md: '40%',
                  }}
                  textAlign="center"
                >
                  <Heading size="lg">
                    Atmosphere
                    {' '}
                    <Text color="purple.400">{customer?.value?.customer?.email}</Text>
                  </Heading>
                </Stack>
                <Stack
                  width={{
                    base: '100%',
                    md: '60%',
                  }}
                >
                  <Text textAlign="center" width="400px">
                    Hi
                    {' '}
                    {customer?.value?.customer?.first_name}
                    !
                    Here you can update your monthly plan, add additional services and update credit card details.
                  </Text>
                </Stack>
              </Stack>

              <Text>
                Configure Atmosphere for
                {' '}
                {svc?.ip || ''}
                {' :: '}
                {' '}
                {svc?.nbn_details?.addressDetail?.formattedAddress}
              </Text>
              <Divider />
              {customer.message !== '' && (
              <Alert variant="left-accent" color="black" status={customer.message.toLowerCase().includes('error') ? 'error' : 'success'}>
                {' '}
                <AlertIcon />
                {' '}
                {customer.message}
              </Alert>
              ) }
              <Atmosphere token={token} subId={svc?.id} />

              <Button
                size="md"
                backgroundColor="teal.500"
                borderRadius="20px"
                variant="solid"
                width="200px"
                onClick={() => setShowAtmosphere(false)}
              >
                Back to services
              </Button>

            </Stack>
          )}
        </Box>
      </GridItem>
      <GridItem bg="black" area="footer">
        <Center style={{ paddingTop: '0%' }}>
          <Text fontSize="xxs" color="white">
            © Neptune Internet 2024. All rights reserved.
            {' '}
          </Text>
        </Center>

        <Center style={{ paddingTop: '0%' }}>
          <Text fontSize="xxs" color="teal.500">
            <Link href="https://status.neptune.net.au" isExternal>Status Page</Link>
          </Text>
        </Center>
      </GridItem>
    </Grid>

  );
};

export default Dashboard;
