import {
  Center, Grid, GridItem, Link, Text,
} from '@chakra-ui/react';
import React from 'react';
import LoginButton from './LoginButton';
import Logo from '../images/neptune-logo-white.png';

const NotAuthenticated = () => (
  <Grid
    templateAreas={`"header"
                      "main"
                      "footer"`}
    h="100%"
    minHeight="100vh"
    flexGrow="column nowrap"
    gap="1"
    color="blackAlpha.700"
    fontWeight="bold"
  >
    <GridItem pl="2" bg="black" area="header">
      <Center>
        <img
          alt="Neptune Internet logo"
          width="300px"
          style={{ paddingTop: '5%' }}
          src={Logo}
        />
      </Center>
    </GridItem>
    <GridItem pl="2" bg="black" area="main">
      <Center style={{ paddingTop: '2%' }}>
        <Text fontSize="xl" color="white">
          Neptune subscribers portal
        </Text>
      </Center>
      <Center style={{ paddingTop: '2%' }}>
        <LoginButton />
      </Center>
    </GridItem>
    <GridItem bg="black" area="footer">
      <Center style={{ paddingTop: '0%' }}>
        <Text fontSize="xxs" color="white">
          © Neptune Internet 2024. All rights reserved.
          {' '}
        </Text>
      </Center>

      <Center style={{ paddingTop: '0%' }}>
        <Text fontSize="xxs" color="teal.500">
          <Link href="https://status.neptune.net.au" isExternal>Status Page</Link>
        </Text>
      </Center>
    </GridItem>
  </Grid>
);

export default NotAuthenticated;
