import React, { useEffect, useState } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  Spinner,
} from '@chakra-ui/react';
import NotAuthenticated from './components/NotAuthenticated';
import Dashboard from './components/Dashboard';

const App = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [avatarLink, setAvatarLink] = useState('');
  const [verified, setVerified] = useState(true);
  const {
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    async function getClaims() {
      const claims = await getIdTokenClaims();

      if (isAuthenticated) {
        const idToken = await getAccessTokenSilently();
        setToken(idToken!);
      }

      if (claims?.email_verified !== undefined) {
        setVerified(!!claims?.email_verified);
      }

      if (claims?.email !== '' && claims?.email !== undefined) {
        setEmail(claims?.email);
      }
      if (claims?.picture !== '' && claims?.picture !== undefined) {
        setAvatarLink(claims?.picture);
      }
    }
    getClaims();
  }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <Center><Spinner color="teal" speed="0.65s" thickness="4px" variant="xxl" marginTop="10%" /></Center>
    );
  }

  if (!isAuthenticated) {
    return <NotAuthenticated />;
  }

  if (!verified) {
    return (
      <>
        <Alert status="error" backgroundColor="pink">
          <AlertIcon />
          <AlertTitle color="black">Please verify your email!</AlertTitle>
          <AlertDescription color="black">
            We have sent you an email verification link. Please check your spam or
            junk folder as well.
          </AlertDescription>
        </Alert>
        <NotAuthenticated />
      </>
    );
  }

  return token !== '' ? (
    <Dashboard email={email} avatarLink={avatarLink} token={token} />
  ) : (
    <NotAuthenticated />
  );
};

export default App;
