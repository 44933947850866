import { InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl, FormLabel, Heading, List, ListItem, SimpleGrid, Spinner, Stack, Switch,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import {
  fetchGroupsAsync, permitAsyncRegex, removePermitAsyncRegex, setGroupsAsync,
} from '../features/customer/customerObjectSlice';

export interface AtmosphereProps {
  subId: string
  token: string
}
export const Atmosphere = ({ subId, token }: AtmosphereProps) => {
  const dispatch = useAppDispatch();
  const customerState = useAppSelector((state: RootState) => state.customer);

  const onlyUnique = (value: any, index: number, array: any[]) => array.indexOf(value) === index;

  let groupsToSet = customerState.value.subscriptions[subId].dns_neptune_groups;
  const groupKeys = Object.keys(customerState?.atmosphere?.groups).map((key) => key);
  const handleChange = (e: any) => {
    if (e && e?.name === 'goog') {
      if (e?.checked) {
        dispatch(permitAsyncRegex({
          token,
          subId,
          payload: {
            /* eslint-disable */
            allow_list_regex_domains: ['(\.|^)googleadservices\.com$', '(\.|^)ad\.doubleclick\.net$', '(\.|^)dartsearch\.net$'],
          },
        }));
      } else {
        dispatch(removePermitAsyncRegex({
          token,
          subId,
          payload: {
            /* eslint-disable */
            allow_list_regex_domains: ['(\.|^)googleadservices\.com$', '(\.|^)ad\.doubleclick\.net$', '(\.|^)dartsearch\.net$'],
          },
        }));
      }

      return
    }

    if (e && e?.checked) {
      groupsToSet = [...groupsToSet, +e.value].filter(onlyUnique);
    }
    if (e && !e.checked) {
      groupsToSet = [...groupsToSet.filter((key) => +key !== +e.value)];
    }
  };

  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: 'flex-start',
        md: 'space-around',
      }}
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems={{ md: 'center' }}
    >

      <Heading size="md" marginRight="40px">
        {subId}
        {' '}
      </Heading>

      <List spacing={3} textAlign="start">
        <ListItem>
          <FormControl
            columns={{ base: 2, lg: 4 }}
            as={SimpleGrid}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e.target);
            }}
          >
            <FormLabel mb="0">
              <InfoIcon />
              {' '}
              Recommended setting that will block popular advertising networks.
              <br />
              <br />
            </FormLabel>
            <Switch
              size="lg"
              colorScheme="teal"
              name="Recommended"
              value={0}
              defaultChecked={
                      customerState?.value?.subscriptions[subId]?.dns_neptune_groups?.includes(0)
                    }
            />
          </FormControl>
        </ListItem>

        <ListItem>
          <FormControl
            columns={{ base: 2, lg: 4 }}
            as={SimpleGrid}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e.target);
            }}
          >
            <FormLabel mb="0">
              <WarningIcon color="pink" />
              {' '}
              Permit shopping lists. If you disable this some websites like Google shopping might stop working, please keep it on if in doubt.
              <br />
            </FormLabel>
            <Switch
              size="lg"
              colorScheme="teal"
              name="goog"
              value="goog"
              defaultChecked={
                      customerState?.value?.subscriptions[subId]?.dns_allowed_regex_domains?.toString()?.includes('google')
              }
            />
          </FormControl>
        </ListItem>
        <ListItem>

          {customerState.status === 'loading' ? <Spinner />
            : (
              <FormControl
                columns={{ base: 2, lg: 2 }}
                as={SimpleGrid}
                onChange={(e) => {
                  e.preventDefault();
                  handleChange(e.target);
                }}
              >
                {Object.keys(customerState?.atmosphere?.groups).map((key) => (
                  <>
                    <FormLabel key={key} htmlFor={key} mb="0">
                      Block
                      {' '}
                      {key}
                    </FormLabel>
                    <Switch
                      colorScheme="teal"
                      name={key}
                      id={key}
                      value={customerState.atmosphere.groups[key]}
                      defaultChecked={
                      customerState?.value?.subscriptions[subId]?.dns_neptune_groups?.includes(customerState.atmosphere.groups[key])
                    }
                    />
                  </>
                ))}
              </FormControl>
            )}
        </ListItem>
      </List>

      <Button
        size="md"
        backgroundColor="teal.500"
        borderRadius="20px"
        width="200px"
        variant="solid"
        onClick={() => {
          dispatch(setGroupsAsync({
            token,
            subId,
            payload: {
              groups: groupsToSet,
            },
          }));
        }}
      >
        Update
      </Button>

    </Stack>
  );
};
