import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './app/store';
import { getConfig, IBackendConfig } from './config';

const container = document.getElementById('root')!;
const root = createRoot(container);

const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        fontFamily: 'Belfast Grotesk, Helvetica, sans-serif',
        bg: 'black',
        color: 'white',
      },
    },
  },
  colors: {
    teal: {
      500: '#00ffff',
    },
    pink: {
      100: '#f72585',
      200: '#f72585',
      300: '#f72585',
      400: '#f72585',
      500: '#f72585',
      600: '#f72585',
      700: '#f72585',
      800: '#f72585',
      900: '#f72585',
    },
    brand: {
      100: '#00ffff',
      900: '##f72585',
    },
  },
});

const cfg: IBackendConfig = getConfig();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={cfg.domain}
        clientId={cfg.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: cfg.audience,
        }}
      >
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
