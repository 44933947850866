import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@chakra-ui/react';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button
      marginLeft="10px"
      color="white"
      colorScheme="pink"
      borderRadius="20px"
      onClick={() => logout()}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
